/** vendor */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

/** lib */
import { useToast } from '../../hooks/useToast'
import { computeQuantity } from '../Utility/ComputeQuantityUnits'

/** state */
import { deleteMealplan, updateMealplan } from '../../actions/user.actions'

const CompleteMealplanDialog = ({
    showDialog = false,
    completedMeals,
    onConfirm = () => { },
    onCancel = () => { } 
}) => {
    // vendor utils
    const dispatch = useDispatch()

    // a9 utils
    const toast = useToast()

    // ui states
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [reusePlan, setReusePlan] = useState(false)
    const [adjustIngredients, setAdjustIngredients] = useState(true)    
    const [newStartDate, setNewStartDate] = useState('')
    
    // user data
    const currentUsersEmail = useSelector(state => state.auth?.user?.user?.email)
    const sharedWithMeFoodStores = useSelector(state => state.user_data?.shared_with_me.filter(m => m.type === 'UserFoodstore'))
    const foodstores = useSelector(state => state.user_data?.user_stores)

    // form data
    const [checkedIngredients, setCheckedIngredients] = useState([])
    const [ingredientsInStores, setIngredientsInStores] = useState([])
    const [ingredientsNotInStores, setIngredientsNotInStores] = useState([])

    useEffect(() => {
        setIsOpen(showDialog)
    }, [showDialog])

    useEffect(() => {
        if(!loading && completedMeals?.length > 0 && foodstores) {
          const meaplanIngredients = []
          const ingredientsInStoresIds = []
          const ingredientsNotinStoresIds = []
    
          completedMeals.forEach((recipe, index) => {
            recipe?.recipe_ingredients.forEach((ingredient, i) => {
              meaplanIngredients.push(ingredient)
            })
          })
    
          meaplanIngredients.forEach(ingredient => {
            let inStock = false
            let itemToAdd = null
            let storeId = null
            let storeName = null
            let storeQuantity = 0
            let date_added = undefined
    
            for(const store of foodstores) {
              for(const item of store?.items) {
                if(item?.value === ingredient?.ingredient_id) {
                    storeId = store.id
                    storeName = store.name
                    inStock = true
                    itemToAdd = item 
                    storeQuantity = item?.quantity
                    date_added = item?.date_added
                    break
                }
              } 
    
              if(inStock)
              {
                break
              }
            }
            
            if(inStock && itemToAdd) {
              ingredientsInStoresIds.push({
                storeId,
                storeName,
                date_added,
                value: ingredient?.ingredient_id, 
                quantity: storeQuantity || 0, 
                measurement: ingredient?.measurement,
              })
            } else {
              ingredientsNotinStoresIds.push({
                value: ingredient?.ingredient_id, 
                quantity: ingredient?.quantity, 
                measurement: ingredient?.measurement
              })
            }
          })
    
          setCheckedIngredients(ingredientsInStoresIds)
          setIngredientsNotInStores(ingredientsNotinStoresIds)
          setIngredientsInStores(ingredientsInStoresIds)
        }
    }, [completedMeals, loading, foodstores])

    const handleReusePlanChange = (e) => {
        setReusePlan(!reusePlan)
    }

    const handleAdjustmentChange = (e) => {
        setAdjustIngredients(!adjustIngredients)
    }

    const handleNewStartDateSelect = (e) => {
        setNewStartDate(e.target.value)
    }

    const handleOnToggleCheckedIngredient = (e, storeIngredient, recipeIngredient) => {
        if (e.target.checked) {
            const newIngredients = [...checkedIngredients, {
                value: storeIngredient?.ingredient_id,
                quantity: storeIngredient?.quantity || 1,
                measurement: storeIngredient?.measurement || 1,
                storeId: storeIngredient?.storeId,
                date_added : storeIngredient?.date_added
            }]
      
            setCheckedIngredients(newIngredients)
        } else {
            const newIngredients = checkedIngredients.filter(
                (item) => {
                    const storeIngredientMatch = 
                        item?.value === recipeIngredient?.ingredient_id && 
                        (storeIngredient?.storeId === item?.storeId && storeIngredient?.date_added === item?.date_added)
                    
                    return !storeIngredientMatch
                }
            )

            setCheckedIngredients(newIngredients)    
        }

        console.warn()
    }
    
    const handleConfirm = () => {
        if(adjustIngredients) {
            // @todo remove from store

            // for each store

                // if the are more than one selected ingredient matches add them together
                // if the total matches quantity is less than or equal the recipe quanitity remove all matches from stores
            
                // if matches total quantity is greater than the recipe amount then remove matches until, or deduct from the store ingredient
                // update the store
        }

        // if(reusePlan) {
        //     dispatch(updateMealplan()).then(() => {
        //       }).catch(() => {
        //       })
        // }
    }

    const handleCancel = () => {
        setIsOpen(false)
        onCancel()
    }

    return (
        <div
            className={`fixed z-50 inset-0 overflow-y-auto ${isOpen ? 'flex' : 'hidden'} items-center justify-center`}
        >
            <div className="fixed inset-0 bg-black opacity-60"></div>

            <div className="fixed bottom-3 left-0 w-full px-2">
                <div className="bg-white p-4 rounded-md shadow-md w-full z-50">
                    <h2 className="text-xl font-semibold mb-4 border-b pb-2 text-center">Complete Mealplan</h2>

                    <div className="flex min-w-full justify-center">
                        <div className="w-full">
                            {
                                adjustIngredients &&
                                    <div className="h-96">
                                        { 
                                            completedMeals.length !== 0 &&
                                                <ul className="space-y-4 overflow-y-auto h-96 pr-2">
                                                    {completedMeals.map((recipe, index) => (
                                                        <li key={index}>
                                                            <strong className="block pb-2">{recipe?.name}</strong>

                                                            {
                                                                recipe?.recipe_ingredients?.map((ingredient, i) => {
                                                                    const ingredientDetail = ingredient.ingredient                                
                                                                    const inStore = checkedIngredients?.filter(i => i?.value === ingredientDetail.ingredient_id)
                                                                    const instoreCount = ingredientsInStores.find(i => i?.value === ingredientDetail.ingredient_id)

                                                                    return (
                                                                        <div key={i}>
                                                                            <div className="border-t border-black py-1 pl-2 flex justify-between items-center">
                                                                                <div className={`${!instoreCount && 'text-red-600'}`}>
                                                                                    {ingredient?.quantity} {ingredient?.measurement} {ingredientDetail?.name}
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                inStore?.length > 0 &&
                                                                                    <div className="mt-[-6px]">
                                                                                        {
                                                                                            inStore?.map((i, idx) => {
                                                                                                if(i?.quantity === 0) { return <></> }

                                                                                                return (
                                                                                                    <div key={idx} className="flex justify-between">
                                                                                                        <div className="text-xs opacity-60 pl-2 pb-1">
                                                                                                            {i?.quantity} {i?.measurement} 
                                                                                                            &nbsp; found in &nbsp;
                                                                                                            {i?.storeName} added: {i?.date_added && new Date(i.date_added).toLocaleDateString()}
                                                                                                        </div>
                                                                                                        <div className="flex items-center">
                                                                                                            <input 
                                                                                                                onChange={(e) => {handleOnToggleCheckedIngredient(e, i, ingredient )}}
                                                                                                                checked={inStore} 
                                                                                                                id={`sendTolist_${i}`} 
                                                                                                                type="checkbox" 
                                                                                                                value={ingredientDetail.ingredient_id}
                                                                                                                className="w-4 h-4 text-black bg-white border-black rounded" 
                                                                                                            />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </div>                                                                        
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                        </li>
                                                    ))}
                                                </ul>
                                        }   
                                    </div> 
                            }

                            <div className="mt-2 pl-8 border-t border-black">
                                <div className="flex py-2  items-center">
                                    <input 
                                        onChange={handleAdjustmentChange}
                                        checked={adjustIngredients} 
                                        id="adjustIngredidents" 
                                        type="checkbox" 
                                        value="adjustIngredidents" 
                                        className="w-4 h-4 text-black bg-white border-black rounded" 
                                    />
                                    <label 
                                        htmlFor="deleteList" 
                                        className="ml-2 text-sm font-medium text-black"
                                    >Adjust and Remove Ingredients</label>
                                </div>

                                <div className="flex py-2 items-center">
                                    <input 
                                        onChange={handleReusePlanChange}
                                        checked={reusePlan} 
                                        id="reusePlan" 
                                        type="checkbox" 
                                        value="reusePlan" 
                                        className="w-4 h-4 text-black bg-white border-black rounded" 
                                    />
                                    <label 
                                        htmlFor="reusePlan" 
                                        className="ml-2 text-sm font-medium text-black"
                                    >Reschedule Plan</label>
                                </div>

                                <div className="flex py-2 items-center opacity-30">
                                    <input 
                                        onChange={() => {}}
                                        disabled
                                        checked={reusePlan} 
                                        id="reusePlan" 
                                        type="checkbox" 
                                        value="reusePlan" 
                                        className="w-4 h-4 text-black bg-white border-black rounded" 
                                    />
                                    <label 
                                        htmlFor="reusePlan" 
                                        className="ml-2 text-sm font-medium text-black"
                                    >Add to food diary (coming soon)</label>
                                </div>
                            </div>

                            
                            {
                                reusePlan && 
                                    <div className="pt-1 mt-2 border-t border-black">
                                        <label className="block py-2 text-xs w-full">New start date</label>
                                        <input 
                                            type="date" 
                                            value={newStartDate} 
                                            onChange={handleNewStartDateSelect}
                                            className="w-full border-black rounded-lg" 
                                        />
                                    </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="flex justify-between mt-3 w-full">
                    <button
                        className="px-4 py-2 bg-white text-black border border-black hover:bg-black hover:text-white rounded mr-1"
                        onClick={handleCancel}
                    >
                        Cancel
                    </button>

                    <button
                        className="px-4 py-2 text-white border bg-black  border-black hover:bg-black hover:text-white rounded ml-1"
                        onClick={handleConfirm}
                    >
                        Confirm
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CompleteMealplanDialog
