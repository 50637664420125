import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import 'flowbite'
import { ToastProvider } from './components/UI/Toast/ToastProvider'
import { ConfirmProvider } from './components/UI/Confirm/ConfirmProvider'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { enableServiceWorker } from './constants'

import store from './store'

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ConfirmProvider>
          <ToastProvider>
            <App />
          </ToastProvider>
        </ConfirmProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals()

if (enableServiceWorker === true) {
  serviceWorkerRegistration.register()
}

if (!enableServiceWorker || enableServiceWorker === 'false') {
  serviceWorkerRegistration.unregister()
}