/** vendor */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

/** lib */
import { IMG_URL, PLACEHOLDER_IMG } from '../../constants/ApiConfiguration'

/** state */
import { getImportedRecipe } from '../../actions/user.actions'
import { Card } from '../../components/UI/Card'

import IngredientsDropdown from '../UI/IngredientsDropdown'
import RecipeStep from './RecipeStep'

export default function ImportedRecipeDetail({ recipe }) {
    if(!recipe) {
        return <></>
    }

    return (
        <>
            {
                recipe &&
                    <div className="min-w-full flex overflow-y-scroll justify-center card-no-footer">
                        <div className="flex flex-col-reverse w-full">
                            <div className="w-full">
                                <div>
                                    <img 
                                        src={recipe?.image?.url ?? recipe?.image[0]}
                                        className="w-full h-auto rounded-lg"
                                    />

                                    <div className="flex w-full justify-between">
                                        <small className="text-left text-black text-xs p-2 block">
                                            Imported from: <a href={recipe?.url ?? recipe?.['@id']} className="underline">{ recipe?.url }</a>
                                        </small>
                                        <small className="text-right text-black text-xs p-2 block w-1/3">Serves: {recipe?.recipeYield}</small>
                                    </div>
                                </div>

                                
                                <div className="my-6">
                                    {recipe.description}
                                </div>

                                <div className="border-t border-black">
                                    {
                                        recipe?.recipeInstructions.map((step, index) => {
                                            const s =  {
                                                name: step?.name,
                                                description: step?.text, 
                                                step_order: index + 1,
                                                image: ''
                                            }

                                            return (
                                                <RecipeStep 
                                                    step={s} 
                                                    key={index} 
                                                />
                                            )
                                        })
                                    }
                                </div>    
                            </div>
                        
                            <IngredientsDropdown 
                                ingredients={
                                    recipe?.recipeIngredient?.map((i, index) => {
                                        return {
                                            value: 'IMPORT_INGREDIENT_' + index,
                                            name: i,
                                        }

                                    })
                                } 
                            />

                        </div>
                    </div>         
            }
        </>        
    )
}
