/** vendor */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { EyeIcon } from '@heroicons/react/solid'

/** lib */
import { formatShortDate } from '../../utils'
import { Card } from '../../components/UI/Card'
import Loading from '../../components/UI/Loading'
import {createMealplan} from '../../services/user.service'
import { useConfirm } from '../../hooks/useConfirm'
import { useToast } from '../../hooks/useToast'
import { removeSelfFromSharedList } from '../../services/share.service'

/** state */
import { 
  deleteMealplan, 
  getUserMealplans, 
  getSharedWithMe 
} from '../../actions/user.actions'

/** components */
import MealPlanOptions from '../../components/MealPlan/MealPlanOptions'
import ShareDialog from '../../components/Dialogs/ShareDialog'
import MealplanIngredientSummaryDialog from '../../components/Dialogs/MealplanIngredientSummaryDialog'
import CreateMealplanDialog from '../../components/Dialogs/CreateMealplanDialog'
import ScheduleMealplanDialog from '../../components/Dialogs/ScheduleMealplanDialog'

export default function MealPlanList() {
  // vendor utils
  const dispatch = useDispatch()
  const navigate = useNavigate()

  // a9 utils
  const confirm = useConfirm()
  const toast = useToast()

  // show / hide dialogs
  const [showOptions, setShowOptions] = useState(false)
  const [showShare, setShowShare] = useState(false)
  const [showCreatePlanDialog, setShowCreatePlanDialog] = useState(false)
  const [showSchedulePlanDialog, setShowSchedulePlanDialog] = useState(false)
  const [showIngredientSummaryDialog, setShowIngredientSummaryDialog] = useState(false)

  // ui states
  const [loading, setLoading] = useState(false)
  const [tab, setTab] = useState('mymealplans')

  // mealplans
  const mealplans = useSelector(state => state.user_data.mealplans)
  const sharedWithMeMealplans = useSelector(state => state.user_data.shared_with_me.filter(m => m.type === 'UserMealplan'))

  // shared with me mealplan permissions
  const currentUsersEmail = useSelector(state => state.auth?.user?.user?.email)
  const currentUsersId = useSelector(state => state.auth?.user?.user?.id)


  const [mealplanContext, setMealplanContext] = useState({
    mealplan_id: null,
    title: '',
    number_of_days: 0,
    mealplan: null,
    isSharedPlan: false,
    canEdit: false
  })

  /**
   * Utils
   */
  const createMealplanPayload = (formData) => {
    const rows = []
    
    for (let num = 0; num <= parseInt(formData?.number_of_days - 1); num++) {
      let date = null

      if (formData?.start_date) {
        const format = formData?.start_date?.split('-').map(i => parseInt(i))
        date = new Date(format[0], format[1] - 1, format[2])
        date.setDate(date.getDate() + num)
      }

      rows.push({
        date: date?.toISOString() || '',
        day: num,
        recipes: []
      })
    }

    const payload = {
      name: formData?.name,
      recipes: rows,
      days: rows?.length  
    }

    if(formData?.start_date && formData?.end_date) {
      payload['start_date'] = new Date(formData?.start_date).toISOString()
      payload['end_date'] = new Date(formData?.end_date).toISOString()
    }

    return payload
  }

  /**
   * Hooks
   */
  useEffect(() => {
      setLoading(true)
      
      dispatch(getUserMealplans()).then((r) => {
          setLoading(false)
      }).catch(() => {
          setLoading(false)
      })
  }, [])

  /**
   * Handlers
   */
  const handleToggleTab = (e) => {
    e.preventDefault()
    setTab(e.target.href.split('#')[1])
  }

  const handleOnShowIngredientSummary = (id) => {
    setShowOptions(false)
    setShowIngredientSummaryDialog(true)
  }

  const handleOnAction = (action, id) => {
    if (action === 'delete')  {
      confirm.open(
        `Delete ${mealplanContext?.title}?`,
        'This action cannot be undone',
        () => {
          setLoading(true)

          dispatch(deleteMealplan(id)).then(() => {
            
            dispatch(getUserMealplans()).then(() => {
              setLoading(false)
              setShowOptions(false)
              toast.open('Meal Plan Deleted.')
            }).catch(() => {
              setLoading(false)
            })

          }).catch(() => {
            setLoading(false)
          })
        }
      )    
    }

    if (action === 'remove') {
      confirm.open(
        `Remove ${mealplanContext?.title} from shared plans?`,
        'This action cannot be undone.',
        () => {
          setLoading(true)

          removeSelfFromSharedList({entity_id: mealplanContext.mealplan_id, entity_type: 'UserMealplan'}).then(() => {
            dispatch(getSharedWithMe()).then(() => {
              setLoading(false)
              setShowOptions(false)
              toast.open('Mealplan Removed.')
            })
          }).catch(() => {
            setLoading(false)
          })

        })
    }
    
    if (action === 'share') {
      setShowOptions(false)
      setShowShare(true)
    }

    if (action === 'edit') {
      navigate(`/user-meal-plans/edit/${id}`)  
    }

    if (action === 'check') {
      handleOnShowIngredientSummary(id)
    }

    if (action === 'schedule')  {
      setShowOptions(false)
      setShowSchedulePlanDialog(true)
    }
  }

  const handleShowOptions = (mealplan) => {
    if(mealplan) {
      const isSharedPlan = parseInt(mealplan?.user_id) !== currentUsersId
      let canEdit = true

      if(isSharedPlan) {
        canEdit = false

        for(const user of mealplan?.shared_with) {
          if(user?.email === currentUsersEmail && user?.canEdit) {
              canEdit = true
              break
          }
        }
      }

      setMealplanContext({
        mealplan_id: mealplan.user_mealplan_id,
        title: mealplan.name,
        shared_with: mealplan.shared_with,
        number_of_days: mealplan?.days,
        mealplan,
        canEdit: canEdit,
        isSharedPlan: isSharedPlan
      })

      setShowOptions(true)
    }
  }

  const handleOnSchedulePlan = (formData) => {
    setShowSchedulePlanDialog(false)
    setLoading(true)

    dispatch(getUserMealplans()).then(() => {
      dispatch(getSharedWithMe()).then(() => {
        setLoading(false)

        setTimeout(() => {
          toast.open('Meal Plan Re-Scheduled.')
        }, 200);
      })
    })
  }

  const handleOnShareUpdate = (share_with) => {
    dispatch(getSharedWithMe())
  }

  const handleCreateMealplan = (formData) => {
    setShowIngredientSummaryDialog(false)
    setLoading(true)

    const payload = createMealplanPayload(formData)

    createMealplan(payload)
      .then((r) => {
        navigate('/user-meal-plans/edit/' + r?.user_mealplan_id)
      }).catch(() => {
        setLoading(false)
      })
  }

  return (
    <div className="pt-16 w-full">
      <Card title="Mealplans">
        {
          loading && <div className="h-[60vh] relative pt-40"><Loading /></div>
        }
        {
          !loading &&
            <div>
              <div className="w-full flex flex-col items-start">
                <ul className="w-full flex justify-center flex-wrap text-sm font-medium text-center border-b pb-2">
                  <li className="w-1/2 pr-1">
                    <a
                      href="#mymealplans"
                      aria-current="page"
                      onClick={handleToggleTab}
                      className={`${tab === 'mymealplans' ? 'active bg-black block text-white' : ''} inline-block py-1 px-2 rounded w-full`}
                    >
                      My Mealplans
                    </a>
                  </li>
                  <li className="w-1/2 pl-1">
                    <a
                      href="#shared"
                      onClick={handleToggleTab}
                      className={`${tab === 'shared' ? 'active bg-black text-white' : ''} inline-block py-1 px-2 rounded w-full`}
                    >
                      Shared with me
                    </a>
                  </li>
                </ul>
              </div>

              {tab === 'shared' ? 
                <div>
                  <ul className="w-full">
                    { 
                        (sharedWithMeMealplans && sharedWithMeMealplans.length > 0) ?
                          sharedWithMeMealplans.map((m, i) => {
                            return (
                              <li className="flex justify-between border-b border-gray-200" key={i}>
                                <div className="text-black">
                                  <small className="text-[10px] leading-none opacity-60">
                                    {
                                      m?.start_date && m?.end_date ?
                                        `${formatShortDate(m?.start_date )} - ${formatShortDate(m?.end_date)}`
                                        : `${m?.days} days `
                                    }

                                  </small>
                                  
                                  <div>
                                    <label className="block leading-none p-0 m-0">{m?.name}</label>
                                  </div>
                                  <small className="text-[10px] leading-none opacity-60"> 
                                    { 
                                      m?.owner_name && `owner: ${m?.owner_name}`
                                    }
                                  </small>  
                                </div> 


                                <div>
                                  <div className="flex justify-end items-center pt-1">
                                    <Link to={`/user-meal-plans/${m?.user_mealplan_id}?view=readonly`}>
                                      <button className="text-xs w-6 h-6 mt-2 px-1 ml-1 text-black rounded-full">
                                        <EyeIcon />
                                      </button>   
                                    </Link>
                                    <button
                                      className="text-xs h-6 w-6 ml-1 text-black rounded-full"
                                      onClick={ (e) => { handleShowOptions(m) } }
                                    >
                                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                      </svg>

                                    </button>
                                  </div>

                                  <small className="opacity-60 block text-center" style={{ fontSize: 8 }}>
                                    <span style={{ fontSize: 8, marginTop: -9}} className="block text-center">last update</span>
                                    {new Date(m?.updated_at).toLocaleDateString().padStart(10, '0')}
                                  </small>
                                </div>
                              </li>
                            )
                          }
                            
                            ) : 
                            
                            <li className="text-center pt-40">
                              <p className="text-center py-4 text-gray-400">No Mealplans Found</p>
                            </li> 
                    }
                  </ul>
                </div> : ''}

              {tab === 'mymealplans' ?
                <ul className="w-full">
                  { 
                      (mealplans && mealplans.length > 0) ?
                          mealplans.sort((a, b) => new Date(a.start_date) - new Date(b.start_date)).map((m, i) => 
                            <li className="flex justify-between items-center pb-2 border-b border-gray-200" key={i}>
                              <div className="text-black">
                                {
                                  m?.start_date && m?.end_date ?
                                    <small className="text-[10px] leading-none opacity-60">
                                      {formatShortDate(m?.start_date)} - {formatShortDate(m?.end_date)}
                                    </small> : <small className="text-[10px] leading-none opacity-60">{m?.days + ' days'}</small>
                                }
                                <label className="block leading-none pt-1">{m.name}</label> 
                              </div> 
                              <div>
                                <div className="flex justify-end items-center pt-1">
                                  <Link to={`/user-meal-plans/${m.user_mealplan_id}?view=readonly`}>
                                    <button className="text-xs w-6 h-6 mt-2 px-1 ml-1 text-black rounded-full">
                                      <EyeIcon />
                                    </button>   
                                  </Link>
                                  <button
                                    className="text-xs h-6 w-6 ml-1 text-black rounded-full"
                                    onClick={ (e) => { handleShowOptions(m) } }
                                  >
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                                    </svg>

                                  </button>
                                </div>
                                <small className="opacity-60 block text-center" style={{ fontSize: 8 }}>
                                  <span style={{ fontSize: 8, marginTop: -9}} className="block text-center">last update</span>
                                  {new Date(m?.updated_at).toLocaleDateString().padStart(10, '0')}
                                </small>
                              </div>
                            </li>
                          ) : 
                          
                          <li className="text-center pt-40">
                            <p className="text-center py-4 text-gray-400">No Mealplans Found</p>
                          </li> 
                  }
                </ul> : ''}
            </div>
        }
      </Card>

      <div className="fixed bottom-0 left-0 w-screen pb-3 flex justify-center">
        {
          !showShare && !showOptions && !showSchedulePlanDialog && !showIngredientSummaryDialog && !showCreatePlanDialog &&
          <button 
            onClick={() => { 
              setShowCreatePlanDialog(true) 
            }}
            type="button" 
            className="text-white bg-black rounded font-medium border border-black text-sm px-5 py-2"
          >Create New</button>
        }
      </div>

      <MealPlanOptions 
        mealplanId={mealplanContext?.mealplan_id} 
        title={mealplanContext?.title} 
        showDialog={showOptions}
        isSharedWithUser={mealplanContext.isSharedPlan}
        canEdit={mealplanContext.canEdit}
        onAction={handleOnAction}
        onCancel={ () => { setShowOptions(false) } } 
      />

      <ShareDialog
          showDialog={showShare}
          entityId={mealplanContext?.mealplan_id}
          entityType="UserMealplan"
          title={`Share ${mealplanContext?.title}`}
          shareWith={mealplanContext?.shared_with}
          onUpdate={handleOnShareUpdate}
          onCancel={ () => { setShowShare(false) } } 
      />

      <ScheduleMealplanDialog
        mealplan={mealplanContext?.mealplan}
        numberOfDays={mealplanContext?.number_of_days} 
        showDialog={showSchedulePlanDialog} 
        onSubmit={(formData) => { handleOnSchedulePlan(formData) }} 
        onCancel={() => { setShowSchedulePlanDialog(false) }}
      />

      <MealplanIngredientSummaryDialog
        mealplanId={mealplanContext?.mealplan_id} 
        showDialog={showIngredientSummaryDialog} 
        title="Ingredient Summary" 
        onClose={() => { setShowIngredientSummaryDialog(false) }}
      />

      <CreateMealplanDialog 
        showDialog={showCreatePlanDialog} 
        onSubmit={(formData) => { handleCreateMealplan(formData) }} 
        onCancel={() => { setShowCreatePlanDialog(false) }} 
      />
  </div>
  )
}