const sidebarItems = [
    {
        label: 'Dashboard',
        route: '/user-dashboard',
        icon: 'HomeModernIcon'
    },
    {
        label: 'Discover',
        route: '/recipes',
        icon: 'SearchIcon'
    },
    {
        label: 'My Cookbook',
        route: '/user-recipes',
        icon: 'FolderIcon'
    },
    {
        label: 'Create Recipe',
        route: '/recipe-creator',
        icon: 'LightBulbIcon'
    },
    {
        label: 'Shopping',
        route: '/user-shopping-lists',
        icon: 'ClipboardListIcon'
    },
    {
        label: 'Planning',
        route: '/user-meal-plans',
        icon: 'CalendarDaysIcon'
    },
    {
        label: 'Diary',
        route: '/diary',
        icon: 'BookOpenIcon'
    },
    {
        label: 'Manage Stores',
        route: '/user-stores',
        icon: 'DatabaseIcon'
    },
    {
        label: 'Notifications',
        route: '/user-notifications',
        icon: 'BellIcon'
    },
    {
        label: 'Account',
        route: '/user-preferences',
        icon: 'AdjustmentsIcon'
    },
]

export default sidebarItems