
import React, { useEffect, useState } from 'react'
import Loading from '../../UI/Loading';
import { validateRecipe } from '../../../services/user.service'
import { Link } from 'react-router-dom';
import TagIcons from '../../UI/TagIcons';
import { fetchPublishRequests } from '../../../services/publishing.service'

export default function SaveRecipeForm({
    onSubmit, 
    onUpdate, 
    onCancel, 
    recipe, 
    isEditMode
}) {
    const [loading, setLoading] = useState(false)

    const [submissionSubmitted, setSubmissionSubmitted] = useState(false)
    const [validationResult, setValidationResult] = useState(undefined)
    const [showValidation, setShowValidation] = useState(false)

    const [existingRequestId, setExistingRequestId] = useState(undefined)
    const [publishRecipe, setPublishRecipe] = useState(false)

    const [serves, setServes] = useState(1)
    

    const validateUserRecipe = async () => {
        setLoading(true)
        
        const validationResult = await validateRecipe(recipe)

        setValidationResult(validationResult)

        if(isEditMode)
        {
            const publishRequests = await fetchPublishRequests()
            const publishRequest = publishRequests.find(r => 
                r?.entity_type === 'UserRecipe' && r.entity_id === recipe?.user_recipe_id
            )

            setServes(recipe?.serves || 1)
            setExistingRequestId(publishRequest?.id ?? undefined)
        }

        setLoading(false)
        setShowValidation(true)
    }

    const checkForPublishRequest =  () => {
        onSubmit(existingRequestId, publishRecipe)
    }

    useEffect(() => {
        if(recipe && !submissionSubmitted)
        {
            setSubmissionSubmitted(true)
            validateUserRecipe()

            if(isEditMode)
            {
                checkForPublishRequest()
            }
        }
    }, [])

    const handleOnChangeServingSize = (e) => {
        setServes(e?.target?.value)

        onUpdate({serves: e?.target?.value})
    }

    const recipeHeader = () => (
        <div className="w-full p-1 px-2 mb-2">
            <small className="text-left text-black uppercase font-semibold pb-2 block">Basic info</small>
            <div className="flex-1 border-b border-black cursor-pointer flex px-2">   
                <div className="w-12 h-12 border border-black rounded-sm mt-1 mr-2 flex-shrink-0"></div>
                <div>                                                
                    <div className="font-medium">{ recipe.name }</div>
                    <div className="text-left flex-wrap text-xs flex-shrink-1 py-1">{recipe.description}</div>
                    <small className="text-xs pb-2 block text-right pt-1">
                        <span>Serves: </span> 
                        <input 
                            type="number" 
                            value={serves}
                            onChange={handleOnChangeServingSize}
                            className="border rounded w-14 p-1 h-6 text-xs text-center" 
                        /> 
                    </small>
                </div>               
            </div>
        </div>
    )

    const ingredientSummary = () => {
        const ingredients = recipe?.ingredients

        return (
            <div className="w-full px-2 mb-2">
                <small className="text-left text-black uppercase font-semibold pb-2 block">ingredients ({ingredients?.length})</small>
                {
                    ingredients?.map((ingredient, index) => {
                        return (
                            <div
                                key={index} 
                                className="px-4 pt-1 flex justify-between w-full text-xs text-center font-light text-black cursor-pointer leading-none"
                            >
                                 <span>{ingredient?.name}</span> <span>{ingredient?.quantity} {ingredient?.measurement ?? ingredient?.default_unit} {ingredient?.treatment}</span>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const stepSummary = () => {
        const steps = recipe?.steps

        return (
            <div className="w-full px-2 pt-2 mb-2">
                <small className="text-left text-black uppercase font-semibold pb-2 block">steps ({steps?.length})</small>

                {
                    steps?.map((step, index) => {
                        return (
                            <div className="flex items-center justify-between space-x-4 px-4 pt-1" key={index}>
                                <small className="text-xs font-light text-black">
                                    {step.title}
                                </small>
                                <small className="text-xs font-light text-black">{step?.timer}</small>
                            </div>
                        )
                    })
                }
            </div>
        )
    }

    const submissionSummary = () => {
        if (loading) {
            return (
                <div className="w-full"><Loading title="fetching tags ..." /></div>
            )
        }

        return (
            <div className="w-full">
                {
                    !loading && validationResult &&
                        <div className="absolute left-0 bottom-0 w-full px-2">
                            <div className="border-t border-black bg-white px-2 pt-2" style={{ boxShadow: '0 -10px 20px rgba(0, 0, 0, 0.2)' }}>
                            {
                                validationResult.banned_words || validationResult.name_taken || validationResult.custom_ingredients ?
                                    <div>
                                        { 
                                            validationResult.banned_words && 
                                                <>Contains banned words or symbols<br/></> 
                                        } 

                                        { 
                                            validationResult.errors && 
                                                <>{ JSON.stringify(validationResult.errors) }</> 
                                        }

                                        { 
                                            validationResult.custom_ingredients && 
                                                <>
                                                    This Recipe Cannot be published as it as unknown ingredients.<br />
                                                    <Link to={'/contact'}>Contact us</Link> to request we list the custom ingredient(s)
                                                </> 
                                        }
                                    </div> :
                                    <div>
                                        { 
                                            !validationResult.banned_words && 
                                            !validationResult.name_taken && 
                                            !validationResult.custom_ingredients &&
                                                <>
                                                    { 
                                                        recipe?.published &&
                                                            <p className="text-[10px] text-red-600 py-0 text-center border-b border-red-200">This recipe is published, it will become private again until reapproved.</p> 
                                                    }

                                                    { 
                                                        existingRequestId &&
                                                            <p className="text-[10px] text-red-600 py-0 text-center border-b border-red-200">The previous publish request will be cancelled.</p> 
                                                    }
                                                
                                                    <div className="flex items-center justify-center">
                                                        <input 
                                                            onChange={() => { setPublishRecipe(!publishRecipe) }}
                                                            checked={publishRecipe} 
                                                            id="publishRecipe" 
                                                            type="checkbox" 
                                                            value="publishRecipe" 
                                                            className="w-4 h-4 text-black bg-white border-black rounded" 
                                                        />
                                                        <label 
                                                            htmlFor="deleteList" 
                                                            className="ml-2 text-xs font-medium text-black"
                                                        >Publish</label>
                                                    </div>

                                                    <div className="py-2 text-center">
                                                        <label className="text-xs">tags</label>
                                                        { 
                                                            validationResult?.tags && 
                                                                <div className="mx-auto">
                                                                    <TagIcons 
                                                                        fodMapWarning={false}
                                                                        allergenWarning={validationResult?.tags?.includes('allergen')}
                                                                        vegetarian={validationResult?.tags?.includes('vegetarian')}
                                                                        vegan={validationResult?.tags?.includes('vegan')}
                                                                        dairyFree={validationResult?.tags?.includes('dairy_free')}
                                                                        glutenFree={validationResult?.tags?.includes('gluten_free')}
                                                                        sugarFree={validationResult?.tags?.includes('sugar_free')}
                                                                    /> 
                                                                </div>
                                                        }
                                                    </div>
                                                </> 
                                        }

                                        {
                                            validationResult.custom_ingredients &&
                                                <p>This recipe cannot be published, but you save can save it for yourself.</p> 
                                        }
                                    </div>    
                            }
                            </div>
                        </div>
                }
            </div>
        )
    }

    return (
        <div className="w-full">
            {
            recipe &&
                <>
                    <div>{ recipeHeader() }</div>
                    <div>{ ingredientSummary() }</div>
                    <div>{ stepSummary() }</div>
                    <div>{ submissionSummary() }</div>
                </>
            }
        </div>
    )
}

