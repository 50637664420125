/** vendor */
import React from 'react'

/** components */
import LoginForm from '../../components/Forms/LoginForm'
import useOrientation from '../../hooks/useOrientations';
import Logo from '../../components/UI/Logo';

export default function Login() {
    const { type } = useOrientation()

    return(
        <div className="fixed top-0 left-0 w-screen h-screen z-50 bg-black">
            { type &&
                <div className="flex flex-col justify-around h-screen w-screen">
                    <div className={`flex flex-1 items-center justify-center w-full mx-auto ${type.includes('landscape') ? 'flex-row' : 'flex-col'}`}>
                        <Logo />
                        <div className="w-full">
                            <LoginForm />
                        </div>
                    </div>
            </div>
            }
        </div>
    )
}