/** vendor */
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

/** lib */
import { removeSelfFromSharedList } from '../../services/share.service'
import { useConfirm } from '../../hooks/useConfirm'
import { useToast } from '../../hooks/useToast'

/** state */
import { 
  getUserMealplan, 
  deleteMealplan,
  getSharedWithMe 
} from '../../actions/user.actions'

/** components */
import MealPlanOptions from '../../components/MealPlan/MealPlanOptions'
import MealplanIngredientSummaryDialog from '../../components/Dialogs/MealplanIngredientSummaryDialog'
import CompleteMealplanDialog from '../../components/Dialogs/CompleteMealplanDialog'
import ShareDialog from '../../components/Dialogs/ShareDialog'
import { Card } from '../../components/UI/Card'
import Loading from '../../components/UI/Loading'
import ScheduleMealplanDialog from '../../components/Dialogs/ScheduleMealplanDialog'

export default function MealPlanDetail() {
  // vendor utils
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  // a9 utils
  const confirm = useConfirm()
  const toast = useToast()

  // show / hide dialogs
  const [showOptions, setShowOptions] = useState(false)
  const [showCompleteDialog, setShowCompleteDialog] = useState(false)
  const [showIngredientSummaryDialog, setShowIngredientSummaryDialog] = useState(false)
  const [showShare, setShowShare] = useState(false)
  const [showSchedulePlanDialog, setShowSchedulePlanDialog] = useState(false)

  // ui states
  const [loading, setLoading] = useState(false)  

  // mealplan data
  const mealplan = useSelector(state => state.current_mealplan)
  const [mealsCompleted, setMealsCompleted] = useState([])

  // shared with me mealplan permissions
  const currentUsersEmail = useSelector(state => state.auth?.user?.user?.email)
  const currentUsersId = useSelector(state => state.auth?.user?.user?.id)

  const [mealplanPermissions, setMealplanPermissions]= useState({ isSharedList: false, canEditList: true})

  /**
   * Hooks
   */
  useEffect(() => {
    setLoading(true)

    dispatch(getUserMealplan(location.pathname.split('/')[2])).then((r) => {
        setLoading(false)
    }).catch((e) => {
        setLoading(false)
    })
  }, [])

  useEffect(() => {
    if(mealplan?.mealplan && currentUsersId) {
      const isSharedPlan = parseInt(mealplan?.mealplan?.user_id) !== currentUsersId
      let canEdit = true

      if(isSharedPlan) {
        canEdit = false

        for(const user of mealplan?.mealplan?.shared_with) {
          if(user?.email === currentUsersEmail && user?.canEdit) {
              canEdit = true
              break
          }
        }
      }

      setMealplanPermissions({isSharedPlan, canEdit})
    }
  }, [mealplan, currentUsersId])



  /**
   * Handlers
   */
  const handleOnShareUpdate = (something) => {
    // dispatch(getSharedWithMe())
    // todo this would be the result of removing when a shared with me list
  }

  const handleOnSchedulePlan = (formData) => {
    setShowSchedulePlanDialog(false)
    setLoading(true)

    dispatch(getUserMealplan(location.pathname.split('/')[2])).then((r) => {
      setLoading(false)

      setTimeout(() => {
        toast.open('Meal Plan Re-Scheduled.')
      }, 200);

    }).catch((e) => {
        setLoading(false)
    })
  }

  const handleShowOptions = () => {
    setShowOptions(true)
  }

  const handleToggleCompleteMeal = (recipeOption, index) => {
    const mealsCompletedCopy = [...mealsCompleted]
    const alreadySelected = mealsCompletedCopy?.find(meal => recipeOption?.value === meal.recipe_id)

    if(alreadySelected)
    {
      const filterOutSelected = mealsCompletedCopy.filter(meal => recipeOption?.value !== meal.recipe_id)
      setMealsCompleted(filterOutSelected)
      
      return
    }

    const recipeDetail = mealplan?.recipes?.find((r) => {
      return recipeOption?.value === r.recipe_id
    })

    if(recipeDetail) {
      mealsCompletedCopy.push(recipeDetail)
      setMealsCompleted(mealsCompletedCopy)
    }
  }

  const handleOnShowIngredientSummary = () => {
    setShowOptions(false)
    setShowIngredientSummaryDialog(true)
  }

  const handleOnAction = (action, id) => {
    if (action === 'delete')  {
      
      confirm.open(
        `Delete ${mealplan?.mealplan?.name}?`,
        'This action cannot be undone',
        () => {
          setLoading(true)

          dispatch(deleteMealplan(id)).then(() => {
            toast.open('Meal Plan Deleted.') 
            navigate(`/user-meal-plans`) 
          }).catch(() => {
            setLoading(false)
          })
        }
      )
    }
    
    if (action === 'share') {
      setShowOptions(false)
      setShowShare(true)
    }

    if (action === 'remove') {
      confirm.open(
        `Remove ${mealplan?.mealplan?.name} from shared Mealplans?`,
        'This action cannot be undone.',
        () => {
          setLoading(true)

          removeSelfFromSharedList({entity_id: id, entity_type: 'UserMealplan'}).then(() => {
            dispatch(getSharedWithMe()).then(() => {
              toast.open('Mealplan Removed.')
              navigate(`/user-meal-plans`)
            })
          }).catch(() => {
            setLoading(false)
          })

        })
    }

    if (action === 'edit') {
      navigate(`/user-meal-plans/edit/${id}`)  
    }

    if (action === 'check') {
      handleOnShowIngredientSummary()
    }

    if (action === 'schedule') {
      setShowOptions(false)
      setShowSchedulePlanDialog(true)
    }
  }

  if(loading) {
    return (<div className="h-[60vh] relative pt-40"><Loading /></div>)
  }

  return (
    <div className="pt-16 min-w-full">
        <Card title={mealplan?.mealplan?.name || ''}
          status={
            <div className="flex">
              <button
                className="text-xs h-6 w-6 text-black rounded-full"
                onClick={ (e) => { handleShowOptions() } }
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                </svg>
              </button>
            </div>
          }
        >
            <div className="overflow-y-auto">
              { 
                mealplan ? 
                  <div>
                    { 
                      mealplan?.mealplan?.recipes.map((day, index) => {
                        return (
                          <div className="border-b border-black pt-8" key={index}>
                            <h3 className="font-light text-xs pb-2">Day: {day?.day + 1}</h3>
                            { 
                              day?.date ? 
                                <h2 className="font-medium text-sm pt-2">{new Date(day.date).toLocaleDateString("en-GB", { 
                                      weekday: 'short', 
                                      year: 'numeric', 
                                      month: 'short', 
                                      day: 'numeric' 
                                  })}
                                </h2> : <></> 
                            }
        
                            {
                              day?.recipes.map((recipe, i) => {
                                if (!recipe?.value)
                                {
                                  return (
                                    <div className="flex justify-between items-center border-t border-black py-2" key={i}>
                                        <div>
                                          <label className="block font-light">{recipe?.name}</label>
                                          <Link to={`/recipe-creator?name=${recipe?.name}`}>
                                            <small className="block text-xs font-light underline">
                                              Create a new Recipe
                                            </small>
                                          </Link>
                                        </div>
                                      
                                      <div className="flex items-center">                            
                                        <div className="flex py-2 items-center mr-2 w-4 h-4">
                                        </div>
                                      </div>
                                    </div>
                                  )
                                }
                                if (recipe?.value)
                                {
                                  const recipeDetails = mealplan?.recipes.find((r) => {
                                    return r.recipe_id === recipe?.value
                                  })
                                  
                                  return (
                                    <div className="flex justify-between items-center border-t border-black py-2" key={i}>
                                      <Link to={`/recipes/${recipeDetails.recipe_id}`}>
                                        <div>
                                          <label className="block font-light">{recipeDetails?.name}</label>
                                          <small className="block text-xs font-light">
                                            {recipeDetails?.recipe_ingredients.length} ingredient(s)
                                          </small>
                                        </div>
                                      </Link>
                                      
                                      <div className="flex items-center">                            
                                        <div className="flex py-2 items-center mr-2">
                                            <input 
                                                onChange={() => { handleToggleCompleteMeal(recipe, i) }}
                                                checked={recipe?.checked} 
                                                id={`complete_item_${recipeDetails.recipe_id}_${i}`} 
                                                type="checkbox" 
                                                value="" 
                                                className="w-4 h-4 text-black bg-white border-black rounded" 
                                            />
                                        </div>
                                      </div>
                                    </div>
                                  )
                                }
                              })
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                : ''
              }
            </div> 
        </Card>

        {
          !showIngredientSummaryDialog && !showCompleteDialog &&
            <div className={`fixed bottom-0 left-0 w-screen px-2 pb-3 flex items-center justify-center ${mealsCompleted.length < 1 && 'opacity-50'}`}>         
              <div>
                <button
                  disabled={mealsCompleted.length < 1}
                  onClick={() => { setShowCompleteDialog(true) }}
                  type="button" 
                  className={`rounded font-medium border border-black text-sm px-5 py-2
                    ${showCompleteDialog ? 'text-black bg-white opacity-30' : 'text-white bg-black'}
                  `}
                >
                  Complete Mealplan
                </button>
              </div>
            </div> 
        }

        <MealPlanOptions 
          mealplanId={mealplan?.mealplan?.user_mealplan_id} 
          title={mealplan?.mealplan?.name} 
          showDialog={showOptions}
          onAction={handleOnAction} 
          onCancel={ () => { setShowOptions(false) } }
          isSharedWithUser={mealplanPermissions?.isSharedPlan}
          canEdit={mealplanPermissions?.canEdit} 
        />

        <MealplanIngredientSummaryDialog 
          showDialog={showIngredientSummaryDialog} 
          title="Ingredient Summary" 
          mealplanId={mealplan?.mealplan?.user_mealplan_id}
          onClose={() => { setShowIngredientSummaryDialog(false) }}
        />

        <CompleteMealplanDialog 
          title="Complete Mealplan"
          completedMeals={mealsCompleted}
          showDialog={showCompleteDialog}
          onCancel={() => { setShowCompleteDialog(false) }}
        />

        <ScheduleMealplanDialog
          mealplan={mealplan?.mealplan}
          numberOfDays={mealplan?.mealplan?.number_of_days} 
          showDialog={showSchedulePlanDialog} 
          onSubmit={(formData) => { handleOnSchedulePlan(formData) }} 
          onCancel={() => { setShowSchedulePlanDialog(false) }}
        />

        <ShareDialog
            showDialog={showShare}
            entityId={mealplan?.mealplan?.user_mealplan_id}
            entityType="UserMealplan"
            title={`Share ${mealplan?.mealplan?.name}`}
            shareWith={mealplan?.mealplan?.shared_with || []}
            onUpdate={handleOnShareUpdate}
            onCancel={ () => { setShowShare(false) } } 
        />
    </div>
  )
}