/** vendor */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

/** Lib */
import { useToast } from '../../hooks/useToast'
import { removeItemsFromStore } from '../../utils/updateItemsInStore'
import { addItemsToShoppinglist } from '../../utils/updateItemsInShoppinglist'

/** state */
import { 
    getShoppinglists, 
    deleteShoppinglist, 
    updateShoppinglist, 
    updateUserStore, 
    getUserStores, 
    getSharedWithMe 
} from '../../actions/user.actions'

const ManageFoodStoreItemsDialog = ({
    foodstore,
    storeItems, 
    canEditStore = false, 
    onAction = (action) => null 
}) => {

    const dispatch = useDispatch()
    const toast = useToast()

    const [itemsToManage, setItemsToManage] = useState([])
    const shoppingLists = useSelector(state => state.user_data.shoppinglists || [])
    const sharedWithMeShoppinglists = useSelector(state => state.user_data?.shared_with_me.filter(m => m.type === 'UserShoppinglist'))
    const [saving, setSaving] = useState(false)
    const [shoppingListOptions, setShoppingListOptions] = useState([])
    const [selectedMoveListId, setSelectedMoveListId] = useState('')

    const currentUsersEmail = useSelector(state => state.auth?.user?.user?.email)

    const removeFromStore = async () => {
        const storeItemsToRemove = [...itemsToManage].filter(i => i?.checked)
        
        const newStoreItems = foodstore?.items.filter(
            (storeItem) => !storeItemsToRemove.some(
                (removeItem) => 
                    storeItem?.value === removeItem?.value &&
                    storeItem?.date_added === removeItem?.date_added
            )
        )
        
        await dispatch(updateUserStore(foodstore?.id, { items: newStoreItems }))
    }

    const sendTolist = async () => {
        const listToUpdate = [...shoppingLists, ...sharedWithMeShoppinglists]
            .find(l => l.user_shoppinglist_id === parseInt(selectedMoveListId))

        let storeItemsToMove = [...itemsToManage].filter(i => i?.checked)
        let matchStoreItemIndexes = []

        try {
            const listToUpdateIngredients = [...listToUpdate.ingredients].map((listToUpdateItem) => {
                const itemToMerge = storeItemsToMove.find((itemToMerge) => listToUpdateItem?.value === itemToMerge?.value)
    
                if(itemToMerge) {
                    const listItemCopy = {...listToUpdateItem}
                    listItemCopy.quantity = parseFloat(listToUpdateItem.quantity) + parseFloat(itemToMerge.quantity)
                    
                    const storeItemsToMoveRemoveMatchIndex = storeItemsToMove.findIndex(item => {
                        return item.value === itemToMerge.value
                    }) 

                    matchStoreItemIndexes.push(storeItemsToMoveRemoveMatchIndex)

                    return listItemCopy
                }
    
                return listToUpdateItem
            })
            storeItemsToMove = [...storeItemsToMove].filter((_, index) => !matchStoreItemIndexes.includes(index))

            const combinedIngredients = [...listToUpdateIngredients, ...storeItemsToMove]
    
            await dispatch(updateShoppinglist(selectedMoveListId, { ingredients: combinedIngredients } ))        
        } catch (error) {
            console.error(error)
        }
    }

    const prepareItems = () => {
        const addEdit = [...storeItems].map((item) => {
            return {...item, ...{ checked: true }}
        })
        
        setItemsToManage(addEdit)
    }

    const calcExpDate = (dateStr, estimated_expiry_days = 14) => {
        const date = new Date(dateStr)
        date.setDate(date.getDate() + estimated_expiry_days)
    
        return date
    }
    
    const formatExpDate = (dateStr, estimated_expiry_days = 14) => {
        return calcExpDate(dateStr, estimated_expiry_days = 14).toDateString()
    }

    useEffect(() => {
        if (storeItems?.length > 0) {
            prepareItems()
        }
    }, [storeItems])

    useEffect(() => {
        if(shoppingLists && shoppingLists.length > 0 && currentUsersEmail) {
            const shoppingListsCopy = [...shoppingLists]
            const shoppingListOptions = shoppingListsCopy.map(i => { return { value: i.user_shoppinglist_id, name: i.name } })
            let editableSharedWithMeLists = []

            if(sharedWithMeShoppinglists && sharedWithMeShoppinglists.length > 0 && currentUsersEmail) {
                editableSharedWithMeLists = [...sharedWithMeShoppinglists].filter((item) => {
                    let canEdit = false

                    for(const user of item?.shared_with) {
                        if(user?.email === currentUsersEmail && user?.canEdit) {
                            canEdit = true
                            break
                        }
                    }

                    return canEdit
                }).map(i => { return { value: i.user_shoppinglist_id, name: i.name } })
            }
            const mergedOptions = [...shoppingListOptions, ...editableSharedWithMeLists]
            setShoppingListOptions(mergedOptions)
        }
    }, [shoppingLists, currentUsersEmail])

    const handleToggleItemChecked = (checked, index) => {
        const itemsToManageCopy = [...itemsToManage]
        itemsToManageCopy[index].checked = checked
        setItemsToManage(itemsToManageCopy)
    }

    const handleCancel = () => {
        onAction('cancel')
    }

    const handleMoveSelect = (e) => {
        setSelectedMoveListId(e.target.value)
    }

    const handleRemove = async () => {
        try {
            setSaving(true)

            if(selectedMoveListId) {
                await sendTolist()
            }

            await removeFromStore()
            onAction('remove')
            await dispatch(getSharedWithMe())
            await dispatch(getUserStores())

            setSaving(false)
            
            toast.open(selectedMoveListId ? 'Items Moved and store updated' : 'Store updated')
        } catch (error) {
            toast.open('Error Updating store, reload and try again.')
        }
    }

    return (
        <div className={`fixed z-50 inset-0 overflow-y-auto flex items-center justify-center`}>
            <div className="fixed inset-0 bg-black opacity-60"></div>
            <div className="bg-white p-2 fixed bottom-16 rounded-md shadow-md w-[95vw] z-50">
                <h2 className="text-xl font-semibold mb-4 border-b pb-2 text-center">Manage Items</h2>
                <div className="border-b border-black w-full justify-between items-center pb-2">
                    <ul className="px-4 w-full h-[60vh] overflow-y-auto">
                    {
                        itemsToManage && itemsToManage?.map((item, index) => {
                            return (
                                <li className="py-3 sm:py-4 border-b border-gray-400 min-w-full" key={index}>
                                    <div className="flex items-center space-x-4">
                                        <div className="flex-1 min-w-0">
                                            <p className={`font-medium text-black`}>
                                                {item?.quantity || 1} {item?.unit || item?.default_unit || ''} {item.name}
                                            </p>

                                            <small className={`text-xs flex`}>
                                                <span>{formatExpDate(item?.date_added, item?.estimated_expiry_days)} </span>
                                            </small>
                                        </div>

                                        {
                                            canEditStore &&
                                            <div className="inline-flex items-center text-base font-semibold text-black">
                                                <input 
                                                    className="w-4 h-4 text-black bg-white border-black rounded"
                                                    type="checkbox" 
                                                    checked={item?.checked}
                                                    onChange={() => { handleToggleItemChecked(!item?.checked, index) }}
                                                />
                                            </div>
                                        }
                                    </div>
                                </li>              
                            )
                        })
                    }
                    </ul>

                    <div className="pt-1 px-4 mt-2 border-t border-black">
                        <label className="block py-2 text-xs w-full">Send to Shopping List</label>
                        <select
                            value={selectedMoveListId}
                            disabled={saving} 
                            id="shoppinglistMove"
                            onChange={(e) => { handleMoveSelect(e)} }
                            className="border border-black text-black text-sm rounded block w-full p-2"
                        >
                            <option value="">Select ...</option>
                            {
                                shoppingListOptions.map((option, i) => {
                                    return (
                                        <option key={i} value={option.value}>{option.name}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                </div>
            </div>

            <div className="flex justify-between w-screen px-2 pt-2 mb-3 fixed bottom-0">
                <button
                    className="px-4 py-2 text-black border bg-white border-black hover:bg-black hover:text-white rounded"
                    onClick={handleCancel}
                    disabled={saving}
                >
                    Close
                </button>
                <button
                    disabled={saving}
                    className={`px-4 py-2 text-white bg-black border border-black hover:bg-black hover:text-white rounded ${saving && 'opacity-60'}`}
                    onClick={handleRemove}
                >
                    {
                        saving ? 
                        <div className="animate-pulse">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 animate-spin ">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                            </svg>
                        </div> : selectedMoveListId ? 'Send and Remove' : 'Remove' 
                    }
                </button>
            </div>
        </div>
    )
}

export default ManageFoodStoreItemsDialog