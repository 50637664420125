import React from 'react'
import { PlayIcon, PauseIcon } from '@heroicons/react/solid'
import { IMG_URL, PLACEHOLDER_IMG } from '../../constants/ApiConfiguration'

export default function RecipeStep({ step, ingredientList = [] }) {

  const stepImage = (step?.image) ? `${IMG_URL}/${step?.image}` : PLACEHOLDER_IMG
  
  const displayFormat = (value) => { 
    return value.split(':').map(i => i?.length < 2 ? '0' + i : i).join(':')
  } 

  return (
    <div className="border-b border-black py-4">
        <div className="flex items-center">
          <span className="block text-white bg-black px-2">{step?.step_order}</span>
          <label className="lowercase pl-2 pb-1 text-lback">{step?.name ?? `Step ${step?.step_order}`}</label>
        </div>

        <div className="flex">
          <p className="pl-4 py-4 whitespace-pre-wrap text-base">
            {step?.description}
          </p>
        </div>

        {
          ingredientList?.filter(i => step?.ingredients?.includes(i?.recipe_ingredient_id))?.length > 0 &&
            <div className="flex flex-wrap border-t border-gray-200">          
              { 
                ingredientList?.filter(i => step?.ingredients?.includes(i?.recipe_ingredient_id)).map((i) => 
                    <span key={i.ingredient_id} className="text-black rounded-lg text-xs m-1">
                      {i?.quantity}{i?.measurement} {i?.treatment} {i?.ingredient?.name},
                    </span> 
                )
              }
            </div>
        }
    </div>
  ) 
}