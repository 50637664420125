/** vendor */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

/** lib */
import { useToast } from '../../hooks/useToast'
import { computeQuantity } from '../Utility/ComputeQuantityUnits'

/** state */
import { 
    deleteMealplan, 
    updateMealplan,
    getUserMealplans,     
    getSharedWithMe,
    getUserStores, 
    updateUserStore
} from '../../actions/user.actions'

const CompleteMealplanDialog = ({
    mealplan = null,
    showDialog = false,
    completedMeals,
    onConfirm = () => { },
    onCancel = () => { } 
}) => {
    // vendor utils
    const dispatch = useDispatch()

    // a9 utils
    const toast = useToast()

    // ui states
    const [isOpen, setIsOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [reusePlan, setReusePlan] = useState(false)
    const [addToDiary, setAddToDiary] = useState(false)
    const [adjustIngredients, setAdjustIngredients] = useState(true)    
    const [newStartDate, setNewStartDate] = useState('')
    
    // user data
    const currentUsersEmail = useSelector(state => state.auth?.user?.user?.email)
    const sharedWithMeFoodStores = useSelector(
        state => state.user_data?.shared_with_me
            .filter(m => m.type === 'UserFoodStore')
                    .filter(shareEntry => (
                        !!shareEntry?.shared_with?.find(i => i.email === currentUsersEmail && i.canEdit === true)
                    )
                )
    )

    const foodstores = useSelector(state => state.user_data?.user_stores)

    // form data
    const [checkedIngredients, setCheckedIngredients] = useState([])

    useEffect(() => {
        setIsOpen(showDialog)
    }, [showDialog])

    useEffect(() => {
        if(!loading && completedMeals?.length > 0 && foodstores) {
            const recipes = []  
            const meaplanIngredients = []
        
            completedMeals.forEach((recipe, index) => {
                recipes.push({name: recipe?.name, id: recipe?.recipe_id})
                    recipe?.recipe_ingredients.forEach((ingredient, i) => {
                    meaplanIngredients.push(ingredient)
                })
            })
    
            const mergedRecipsIngredientsByQuantity = Object.values(
                meaplanIngredients.reduce((acc, item) => {

                if (acc[item.ingredient_id]) {
                    acc[item.ingredient_id].quantity = parseFloat(acc[item.ingredient_id].quantity ?? 0) + parseFloat(item.quantity)
                } else {
                    acc[item.ingredient_id] = { ...item }
                }

                return acc
                }, {})
            )

            const stockCheckedItems = []

            for(const store of [...foodstores, ...sharedWithMeFoodStores]) {

                for(const item of store?.items) {
                    const matchedStoreIngredient = mergedRecipsIngredientsByQuantity?.find(i => item?.value === i?.ingredient_id)    

                    if(matchedStoreIngredient) {
                        stockCheckedItems.push({...item, storeName: store?.name, storeId: store?.id})
                    }
                }
            }

            const mergedRecipesIngredientsWithInStockItems = mergedRecipsIngredientsByQuantity.map((item) => {
                let remainingQuantity = item?.quantity ?? 0

                const stockItems = stockCheckedItems
                    .filter((si) => { return si?.value === item?.ingredient_id })
                    .sort((a, b) => b?.date_added.localeCompare(a?.date_added))
                    .map((si) => {
                        const newObj = {
                            ...si,
                            quantity: si?.quantity ?? 1,
                            checked: remainingQuantity > 0
                        }
                        
                        remainingQuantity = parseFloat(remainingQuantity) - parseFloat(si?.quantity)
                        
                        return newObj
                    })

                return {...item, stock: stockItems}
            })

            setCheckedIngredients(mergedRecipesIngredientsWithInStockItems)
        }
    }, [completedMeals, loading, foodstores])

    const handleReusePlanChange = (e) => {
        setReusePlan(!reusePlan)
    }

    const handleAdjustmentChange = (e) => {
        setAdjustIngredients(!adjustIngredients)
    }

    const handleNewStartDateSelect = (e) => {
        setNewStartDate(e.target.value)
    }

    const handleOnToggleCheckedIngredient = (e, ingredientGroup, stockItem) => {
        const checkedIngredientsUpdateIndex = checkedIngredients?.findIndex(
            i => i?.ingredient_id === ingredientGroup?.ingredient_id
        )
        
        const updateStockIndex = checkedIngredients[checkedIngredientsUpdateIndex]?.stock?.findIndex(i => 
            stockItem?.storeId === i?.storeId &&
            stockItem?.date_added === i?.date_added &&
            stockItem?.ingredient_id === i?.ingredient_id
        )

        const updatedCheckedIngredients = [...checkedIngredients]

        updatedCheckedIngredients[checkedIngredientsUpdateIndex].stock[updateStockIndex].checked = e.target.checked

        setCheckedIngredients(updatedCheckedIngredients)
    }
    
    const handleConfirm = async () => {
        setSaving(true)

        try {
            
            if(adjustIngredients) {
                const stores = [
                    ...sharedWithMeFoodStores, 
                    ...foodstores
                ]
                
                // get the totals per ingredient across all recipes
                const ingredientQuantitiesToBeSubtracted = [...checkedIngredients]
                    // only items where there is stock
                    .filter(i => i?.stock?.length > 0)
                    // filter out properties we dont need
                    .flatMap(i => { 
                        return { 
                            quantity: parseFloat(i?.quantity) ?? 1, 
                            ingredient_id: i?.ingredient_id
                        }
                    })
                    // add quantities together where the ingredient_id is the same
                    .reduce((acc, i) => {
                        const key = i?.ingredient_id
    
                        if (!acc[key]) acc[key] = []
                        acc[key] =+ parseFloat(i?.quantity ?? 1)
                        return acc
                    }, {})
    
                // prepare the checked ingredients to be updated in the stores, group them by store id
                const recipeIngredientsToUpdateStoresWith = [...checkedIngredients]
                    // only items where there is stock
                    .filter(i => i?.stock?.length > 0)
                    // flatten to just get the stock arrays
                    .flatMap(i => i.stock)
                    // sort by oldest date_added
                    .sort((a, b) => a?.date_added.localeCompare(b?.date_added))
                    // fitler out item the user didnt select
                    .filter(i => i?.checked)
                    // update the quantities
                    .map((recipeStoreIngredient) => {
                        const ingredient_id = recipeStoreIngredient?.value
                        let newQuantity = parseFloat(recipeStoreIngredient?.quantity ?? 1)
    
                        if(ingredientQuantitiesToBeSubtracted[ingredient_id] > 0 && newQuantity > 0) {
    
                            if(ingredientQuantitiesToBeSubtracted[ingredient_id] >= newQuantity) {
                                ingredientQuantitiesToBeSubtracted[ingredient_id] = ingredientQuantitiesToBeSubtracted[ingredient_id] - newQuantity
                                newQuantity = 0
                            } else {
                                newQuantity = newQuantity - ingredientQuantitiesToBeSubtracted[ingredient_id]
                                ingredientQuantitiesToBeSubtracted[ingredient_id] = 0
                            }

                        }
    
                        return {
                            ...recipeStoreIngredient,
                            quantity: newQuantity
                        }
                    })
                    // group by store
                    .reduce((acc, stockItem) => {
                        const key = stockItem.storeId
    
                        if (!acc[key]) acc[key] = []
                        acc[key].push(stockItem)
                        return acc
                    }, {})
    
                // update each store
                for(const [storeId, itemsToUpdate] of Object.entries(recipeIngredientsToUpdateStoresWith)) {
                    const storeToUpdate = stores?.find(s => s?.id === parseInt(storeId))
    
                    if(storeToUpdate) {
                        let updatedStoreItems = [...storeToUpdate?.items]
                        const indexesToRemove = []

                        for(const item of itemsToUpdate) {
                            const indexToUpdate = updatedStoreItems
                                .findIndex((i) => { 
                                    return i?.value === item?.value 
                                })

                            updatedStoreItems[indexToUpdate] = item
                            updatedStoreItems[indexToUpdate].checked = false

                            if(parseFloat(updatedStoreItems[indexToUpdate]?.quantity) <= 0) {
                                indexesToRemove.push(indexToUpdate) 
                            }
                        }

                        updatedStoreItems = updatedStoreItems
                            .filter((_, index) => !indexesToRemove.includes(index))

                        try {
                            await dispatch(updateUserStore(storeId, {items: updatedStoreItems}))

                            toast.open(`${itemsToUpdate?.length} ingredients in ${storeToUpdate?.name} updated successfully`)
                        } catch (error) {
                            console.error('error updating store',  error)
                            toast.open(`error updating ${storeToUpdate?.name}`)
                        }
                    }
                }

                // retrieve the latest stores
                await dispatch(getUserStores())
            }
    
            if(reusePlan) {
                if (newStartDate) {
                    try {
                        // fill in the new dates
                        const end_d = new Date(newStartDate)
                        end_d.setDate(end_d.getDate() + mealplan?.recipes?.length - 1)
    
                        const payload = { 
                            ...mealplan, 
                            start_date: new Date(newStartDate).toISOString(),
                            end_date: end_d.toISOString()
                        }
                  
                        payload.recipes = payload?.recipes.map((day, index) => {
                          const d = new Date(newStartDate)
                          d.setDate(d.getDate() + index)
                  
                          const dayEntry = { ...day }
                          dayEntry.date = d.toISOString()
                  
                          return dayEntry
                        })
                        
                        // saving the dates
                        await dispatch(updateMealplan(mealplan?.user_mealplan_id, payload))
                        await dispatch(getUserMealplans())
                    } catch (error) {
                        console.error('error updating mealplan',  error)
                        toast.open(`error updating ${mealplan?.name}`)
                    }
                }
            }
    
            if(addToDiary) {
                // @todo
            }

            await dispatch(getSharedWithMe())
            onConfirm()
        } catch (error) {
            console.error('Error updating stores')
        }

        setSaving(false)
    }

    const handleCancel = () => {
        setIsOpen(false)
        onCancel()
    }

    return (
        <div
            className={`fixed z-50 inset-0 overflow-y-auto ${isOpen ? 'flex' : 'hidden'} items-center justify-center`}
        >
            <div className="fixed inset-0 bg-black opacity-60"></div>

            <div className="fixed bottom-3 left-0 w-full px-2">
                <div className="bg-white p-4 rounded-md shadow-md w-full z-50">
                    <h2 className="text-xl font-semibold mb-4 border-b pb-2 text-center">Update Stock</h2>

                    <div className="flex min-w-full justify-center">
                        <div className="w-full">
                            {
                                adjustIngredients &&
                                    <div className={reusePlan ? 'h-72' : 'h-96'}>
                                        { 
                                            checkedIngredients.length !== 0 &&
                                                <ul className={`space-y-4 overflow-y-auto ${reusePlan ? 'h-72' : 'h-96'} pr-2`}>
                                                    {
                                                        checkedIngredients?.map((ingredient, index) => {
                                                            const ingredientDetail = ingredient?.ingredient                                
                                                            const inStore = ingredient?.stock?.length > 0
                                                            
                                                            if(!inStore) return null

                                                            return (
                                                                <li key={index}>
                                                                    <div>
                                                                        <div className="pt-3 pb-2 pl-2 flex justify-between items-center">
                                                                            <div className={`${!inStore && 'text-red-600'}`}>
                                                                                {ingredient?.quantity} {ingredient?.measurement} {ingredientDetail?.name}
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            ingredient?.stock?.length > 0 &&
                                                                                <div className="">
                                                                                    {
                                                                                        ingredient?.stock?.map((i, idx) => {
                                                                                            return (
                                                                                                <div key={idx} className="flex justify-between items-center border-t">
                                                                                                    <div className="text-xs opacity-60 pl-2 leading-6">
                                                                                                        {i?.quantity} {i?.measurement} |&nbsp; 
                                                                                                        {i?.storeName} | {i?.date_added && new Date(i.date_added).toLocaleDateString().replaceAll('/', '-')}
                                                                                                    </div>
                                                                                                    <div className="flex items-center">
                                                                                                        <input 
                                                                                                            onChange={(e) => {handleOnToggleCheckedIngredient(e, ingredient, i )}}
                                                                                                            checked={i?.checked} 
                                                                                                            id={`sendTolist_${i}`} 
                                                                                                            type="checkbox" 
                                                                                                            value={ingredientDetail.ingredient_id}
                                                                                                            className="w-4 h-4 text-black bg-white border-black rounded" 
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </div>                                                                        
                                                                        }
                                                                    </div>
                                                                </li>
                                                            )
                                                        })      
                                                    }
                                                </ul>
                                        }   
                                    </div> 
                            }

                            <div 
                                className="pt-2 pl-8 border-t border-black"
                            >
                                <div className="flex py-2  items-center">
                                    <input 
                                        onChange={handleAdjustmentChange}
                                        checked={adjustIngredients} 
                                        id="adjustIngredidents" 
                                        type="checkbox" 
                                        value="adjustIngredidents" 
                                        className="w-4 h-4 text-black bg-white border-black rounded" 
                                    />
                                    <label 
                                        htmlFor="deleteList" 
                                        className="ml-2 text-sm font-medium text-black"
                                    >Adjust and Remove Ingredients</label>
                                </div>

                                <div className="flex py-2 items-center">
                                    <input 
                                        onChange={handleReusePlanChange}
                                        checked={reusePlan} 
                                        id="reusePlan" 
                                        type="checkbox" 
                                        value="reusePlan" 
                                        className="w-4 h-4 text-black bg-white border-black rounded" 
                                    />
                                    <label 
                                        htmlFor="reusePlan" 
                                        className="ml-2 text-sm font-medium text-black"
                                    >Reschedule Plan</label>
                                </div>

                                <div className="flex py-2 items-center opacity-30">
                                    <input 
                                        onChange={() => {}}
                                        disabled
                                        checked={addToDiary} 
                                        id="reusePlan" 
                                        type="checkbox" 
                                        value="reusePlan" 
                                        className="w-4 h-4 text-black bg-white border-black rounded" 
                                    />
                                    <label 
                                        htmlFor="reusePlan" 
                                        className="ml-2 text-sm font-medium text-black"
                                    >Add to food diary (coming soon)</label>
                                </div>
                            </div>

                            
                            {
                                reusePlan && 
                                    <div className="pt-1 mt-2 border-t border-black">
                                        <label className="block py-2 text-xs w-full">New start date</label>
                                        <input 
                                            type="date" 
                                            value={newStartDate} 
                                            onChange={handleNewStartDateSelect}
                                            className="w-full border-black rounded-lg" 
                                        />
                                    </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="flex justify-between mt-3 w-full">
                    <button
                        className="px-4 py-2 bg-white text-black border border-black hover:bg-black hover:text-white rounded mr-1"
                        onClick={handleCancel}
                        disabled={saving}
                    >
                        Cancel
                    </button>

                    <button
                        disabled={saving}
                        className="px-4 py-2 text-white border bg-black  border-black hover:bg-black hover:text-white rounded ml-1"
                        onClick={handleConfirm}
                    >
                        {   
                            saving ? 
                                <div className="animate-pulse">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 animate-spin ">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                    </svg>
                                </div> : 'Confirm'
                        }
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CompleteMealplanDialog
