/** vendor */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useNavigate, useLocation, useParams } from 'react-router-dom'

/** lib */
import { Card } from '../../components/UI/Card'
import Stepper from '../../components/UI/Stepper'
import Loading from '../../components/UI/Loading'
import { useToast } from '../../hooks/useToast'

/** state */
import { getFormOptions } from '../../actions/form.actions'

/** components */
import CreateBasicDetailsForm from '../../components/Forms/RecipeCreator/CreateBasicDetailsForm'
import CreateIngredientsForm from '../../components/Forms/RecipeCreator/CreateIngredientsForm'
import ConfigureIngredientsForm from '../../components/Forms/RecipeCreator/ConfigureIngredientsForm'
import CreateStepsForm from '../../components/Forms/RecipeCreator/CreateStepsForm'
import SaveRecipeForm from '../../components/Forms/RecipeCreator/SaveRecipeForm'
import { createUserRecipe,  getUserRecipes, updateUserRecipe } from '../../actions/user.actions'

export default function RecipeCreator() {
  // vendor utils
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const { id } = useParams()

  // a9 utils
  const toast = useToast()

  // ui states
  const isEditMode = !!id
	const [currentStep, setCurrentStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)
  const [isValid, setIsValid] = useState(false)

  const [shouldPublish, setShouldPublish] = useState(false)
  const [existingRequestId, setExistingRequestId] = useState(false)

  const [formErrors, setFormErrors] = useState(null)
  const [validSteps, setValidSteps] = useState([])

  const recipes = useSelector(state => state?.user_data?.user_recipes)

  const [recipe, setRecipe] = useState({
    category_id: null,
    name: '',
    ingredients: [],
    steps: [],
    serves: 1,
    recipe_image: null
  })
  
  const basePath = isEditMode ? `/recipe-edit/${id}` : '/recipe-creator';

  const stepArray = [
    { description: 'Basic Details', to: `${basePath}` },
    { description: 'Add Ingredients', to: `${basePath}/ingredients` },
    { description: 'Configure Ingredients', to: `${basePath}/configure` },
    { description: 'Add Steps', to: `${basePath}/steps` },
    { description: 'Review and Save', to: `${basePath}/complete` },
  ]

  /**
   * Utils
   */
  const validateRecipe = () => {
    const validSteps = []
    const errors = {}
  
    if (!recipe.name || recipe.name.trim() === '') {
      errors.name = 'Recipe name is required'
    }

    if (!recipe.description || recipe.description.trim() === '') {
      errors.description = 'Recipe description is required'
    }

    if(!errors?.description && !errors?.name) {
      validSteps.push(0)
    }
  
    if (!recipe.ingredients || recipe.ingredients.length === 0) {
      errors.ingredients = 'At least one ingredient is required'
    } else {
      validSteps.push(1)
      validSteps.push(2)
    }

    if (!recipe.steps || recipe.steps.length === 0) {
      errors.steps = {}
      errors.steps.error = 'At least one step is required'
    } else {
      recipe.steps.forEach((step, index) => {
        if (!step.title || step.title.trim() === '') {
          if(!errors?.steps) errors.steps = {}
          errors.steps[`step_${index}_title`] = `Step ${index + 1} title is required`
        }
        if (!step.description || step.description.trim() === '') {
          if(!errors?.steps) errors.steps = {}
          errors.steps[`step_${index}_description`] = `Step ${index + 1} description is required`
        }
      })
    }

    if(Object.keys(errors).length === 0) {
      validSteps.push(3)
      validSteps.push(4)
      setIsValid(true)
      setFormErrors(null)
    } else {
      setIsValid(false)
      setFormErrors(errors)
    }

    setValidSteps(validSteps)    
  }

  const saveFormProgress = () => {
    localStorage.setItem('draft_recipe', JSON.stringify(recipe))
  } 

  /**
   * Hooks
   */
  useEffect(() => {
    if(isEditMode) {
      const recipeToEdit = recipes.find(r => r.user_recipe_id === parseInt(id))
      setRecipe(recipeToEdit)
    } 
  }, [isEditMode])

  useEffect(() => {
    if(location?.search?.length > 0)
    {
      const queryParams = new URLSearchParams(location.search)
      const name = queryParams.get('name')
      const recipeCopy = {...recipe}
      
      recipeCopy.name = name

      setRecipe(recipeCopy)

    }
  }, [location?.search])

  useEffect(() => {
    validateRecipe()
  }, [recipe])

  useEffect(() => {
    if(currentStep > 1) {
      saveFormProgress()
    }
  }, [currentStep])

  useEffect(() => {
    setLoading(true)

    dispatch(getFormOptions()).then(() => {
        setLoading(false)
    }).catch(() => {
        setLoading(false)
    })

    setCurrentStep(stepArray.findIndex(i => i.to === location.pathname) + 1)
  }, [])

  /**
   * Handlers
   */
  const handleSubmitRecipe = async () => {
    setSaving(true)

    if(isEditMode) {
      try {

        if(existingRequestId) {
          // @todo cancel the previous publish request if there is one
        }

        await dispatch(updateUserRecipe(id, recipe))
        
        if(shouldPublish) {
          // @todo create a new publish request if one was requested
        }

        await dispatch(getUserRecipes())

        setSaving(false)
        toast.open('Recipe updated')

        setTimeout(() => {
          navigate('/user-recipes/' + id)
        }, 500)
      } catch (error) {
        toast.open('Error updating recipe')
        setSaving(false)
      }

    } else {

      try {
        const createdRecipe = await dispatch(createUserRecipe(recipe))
        await dispatch(getUserRecipes())
        
        setSaving(false)
        toast.open('Recipe created')
        setTimeout(() => {
          navigate('/user-recipes/' + createdRecipe.user_recipe_id)
        }, 500)
      } catch (error) {
        toast.open('Error creating recipe')
        setSaving(false)
      }

    }
  }

	const handleClick = (clickType) => {
		let newStep = currentStep;

		(clickType == "next") ? newStep++ : newStep--

		// Check if steps are within the boundary
		if (newStep > 0 && newStep <= stepArray.length) {
			setCurrentStep(newStep)
      navigate(stepArray[newStep - 1].to)
		}
	}

  const onUpdateFormState = (newState) => {
    setRecipe({...recipe, ...newState})
  }

  const onCompleteForm = (
    existingRequestId = null, 
    shouldPublish = false
  ) => {
    setExistingRequestId(existingRequestId)
    setShouldPublish(shouldPublish)
  }

  if (loading) {
    return (<div className="h-[60vh] relative pt-40"><Loading /></div>)
  }

  return (
    <div className="pt-16 min-w-full relative">
      <Card title="Create Recipe" enableBack={false}>
          <Stepper steps={stepArray} currentStepNumber={currentStep} validSteps={validSteps}/>
          <div className="m-h-[72vh] overflow-y-scroll flex justify-center items-center">     
            <Routes>
              <Route path="/" element={<CreateBasicDetailsForm onUpdate={onUpdateFormState} recipe={recipe} />} />
              <Route path="/ingredients" element={<CreateIngredientsForm onUpdate={onUpdateFormState} recipe={recipe} />} />
              <Route path="/configure" element={<ConfigureIngredientsForm onUpdate={onUpdateFormState} recipe={recipe} />} />
              <Route path="/steps" element={<CreateStepsForm onUpdate={onUpdateFormState} recipe={recipe} />} />
              <Route path="/complete" element={<SaveRecipeForm onSubmit={onCompleteForm} isEditMode={isEditMode} onCancel={() => { console.warn('todo: onCancel') }} recipe={recipe} />} />
            </Routes> 
          </div>
      </Card>

      <div className="fixed bottom-0 left-0 w-screen py-4 flex justify-between px-2">
        <button
          disabled={currentStep < 2} 
          onClick={() => handleClick()} 
          className={`${currentStep < 2 ? 'opacity-60' : ''} text-black bg-white rounded font-medium border border-black text-sm px-5 py-2`}
        >Back</button>

        {
          currentStep === stepArray?.length &&
            <button
              disabled={!isValid} 
              onClick={() => handleSubmitRecipe() } 
              className={`${!isValid ? 'opacity-60' : ''} text-white bg-black rounded font-medium border border-black text-sm px-5 py-2`}
            >Submit</button>
        }

        {
          currentStep !== stepArray?.length &&
            <button
              onClick={() => handleClick("next") } 
              className={`text-white bg-black rounded font-medium border border-black text-sm px-5 py-2`}
            >Next</button>
        } 
      </div>

      {
        saving && 
          <div className="h-full w-full fixed top-0 left-0 opacity-60 bg-white flex items-center justify-center pt-60">
            <Loading title="Saving" />
          </div>
      }
    </div>
  )
}