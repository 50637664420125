/** vendor */
import React, { useState, useEffect } from 'react'

import { treatment_options, measurement_select_options } from '../../../constants'

export default function ConfigureIngredientsForm({onUpdate, recipe}) {
    const [focusIndex, setFocusindex] = useState(0)
    const [selectedIngredients, setSelectedIngredients] = useState([])

    /**
     * Hooks
     */
    useEffect(() => {
        if (recipe?.ingredients) {
            const ingredientsCopy = [...recipe?.ingredients].map(
                (i) => {
                    return {
                        ...i, 
                        ...{
                            quantity: i?.quantity || 1, 
                            measurement: i?.measurement || i?.default_unit,
                            treatment: i?.treatment  
                        }
                    }

                }
            )
            setSelectedIngredients(ingredientsCopy)
        }
    }, [recipe])

    /**
     * Handlers
     */
    const handleTreatmentChange = (e, i) => {
        const selectedIngredientsCopy = [...selectedIngredients]
        selectedIngredientsCopy[i].treatment = e.target.value
        setSelectedIngredients(selectedIngredientsCopy)

        onUpdate({ingredients: selectedIngredientsCopy})
    }

    const handleQuantityChange = (e, i) => {
        const selectedIngredientsCopy = [...selectedIngredients]
        selectedIngredientsCopy[i].quantity = e.target.value
        setSelectedIngredients(selectedIngredientsCopy)

        onUpdate({ingredients: selectedIngredientsCopy})
    }

    const handleMeasurementChange = (e, i) => {
        const selectedIngredientsCopy = [...selectedIngredients]
        selectedIngredientsCopy[i].measurement = e.target.value

        setSelectedIngredients(selectedIngredientsCopy)
        onUpdate({ingredients: selectedIngredientsCopy})
    }

    return (
            <div className="min-w-full h-[60vh] pt-4">
                <div className="relative overflow-x-auto sm:rounded-lg w-full h-[56vh] overflow-y-scroll">

                    <ul role="list" className="divide-y divide-black min-w-full border-t border-b border-black">
                        {
                            selectedIngredients ? 
                                selectedIngredients.map((ingredient, index) => { 
                                    return (
                                        <li className={`${ index !== focusIndex ? '' : 'border-black' }`} key={index}>
                                            <div 
                                                className="w-full py-2 bg-gray-50 text-sm text-center font-light text-black cursor-pointer"
                                                onClick={() => { setFocusindex(index) }}
                                            >
                                                {   index !== focusIndex 
                                                        ? `${ingredient?.quantity} ${ingredient?.measurement} ` 
                                                        : ''
                                                }
                                                {ingredient?.name} 
                                            </div>
                                            {
                                                index === focusIndex ?
                                                <div className="flex justify-center pb-2">
                                                    <div className="w-1/3 flex-grow-0">
                                                        <label className="block py-2 w-24 text-xs text-center">Quantity</label>
                                                        <input 
                                                            className="border w-full border-black text-black text-center text-sm rounded" 
                                                            id="quantity" 
                                                            type="number"
                                                            min="1"
                                                            onChange={(e) => { handleQuantityChange(e, index)} }
                                                            value={ingredient?.quantity} 
                                                        />
                                                    </div>
                                                    <div className="w-1/3 flex-grow-0 px-2">
                                                        <label className="block py-2 text-xs text-center">measurement</label>
                                                        <select 
                                                            id="measurement"
                                                            onChange={(e) => { handleMeasurementChange(e, index)} }
                                                            value={ingredient?.measurement}  
                                                            className="border border-black text-black text-sm rounded block w-full p-2"
                                                        >
                                                            {
                                                                measurement_select_options?.map((option, i) => {
                                                                    return <option key={i} value={option.value}>{option?.label}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="w-1/3 flex-grow-0">
                                                        <label className="block py-2 text-xs text-center">preparation</label>
                                                        <select 
                                                            id="treatment"
                                                            onChange={(e) => { handleTreatmentChange(e, index)} }
                                                            value={ingredient?.treatment}  
                                                            className="border border-black text-black text-sm rounded block w-full p-2"
                                                        >
                                                            {
                                                                treatment_options?.map((option,i) => {
                                                                    return <option value={option} key={i}>{option}</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div> : ''
                                            }
                                        
                                        </li>
                                    )
                                }) : <li>No Ingredients.</li>
                        }
                    </ul>
                </div>
            </div>
    )
}

