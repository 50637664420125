/** vendor */
import React, {useState} from 'react'
import { useDispatch } from 'react-redux'

/** state */
import { importRecipe } from '../../actions/user.actions'

export default function ImportForm({ 
    onImportSuccess = () => {},
    onImportFail = () => {} 
}) {
    const dispatch = useDispatch()
    const [url, setUrl] = useState('')
    const [importing, setImporting] = useState(false)

    const isValidHttpUrl = (string) => {
        let url
        try {
          url = new URL(string)
        } catch (_) {
          return false
        }
        return url.protocol === 'http:' || url.protocol === 'https:'
    }

    const handleChange = async e => {
        e.preventDefault()
        setUrl(e.target.value)
    }

    const handleClick = async e => {
        e.preventDefault()

        if(isValidHttpUrl(url)) {
            setImporting(true)

            try {
                await dispatch(importRecipe({ url }))
                setImporting(false)
                onImportSuccess()
            } catch (error) {
                console.error(error)
                setImporting(false)
                onImportFail()
            }
        }
    }

    return (
        <div className="flex justify-center items-center mb-4">
            <div className="mb-2 pt-2 w-96">
                <div className="flex">
                    <input 
                        type="text" 
                        id="recipe" 
                        className=" border text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pr-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" 
                        placeholder="enter a url"
                        onChange={handleChange}
                    />
                    <div className="pl-2">
                        <button
                            onClick={(e) => { handleClick(e) }}
                            className="bg-white border border-black hover:bg-black hover:text-white font-bold py-2 px-4 rounded" 
                            type="submit"
                        >
                            {
                                importing ? 
                                    <div className="animate-pulse">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 animate-spin ">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                        </svg>
                                    </div> : 'Import'
                            }
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

