import React, { useState } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/outline'

export default function TagIcons({
    fodMapWarning = false,
    allergenWarning,
    vegetarian,
    vegan,
    glutenFree,
    sugarFree,
    dairyFree
}) {
    return (
        <div className="flex py-2 justify-center">
            { allergenWarning && 
                <div className="mr-1 mt-[-2px]">
                    <ExclamationCircleIcon className="h-6 w-6 mx-auto block"/>
                    <small className="text-[6px] block text-center mt-[-3px]">allergens</small>
                </div> 
            }
            { vegetarian && !vegan && <span className="border border-black py-1 mr-2 text-black text-xs w-6 h-6 text-center rounded-full">V</span> }
            { vegan && <span className="border border-black py-1 mr-2 text-black text-xs w-6 h-6 text-center rounded-full">VG</span> }
            { dairyFree && <span className="border border-black py-1 mr-2 text-black text-xs w-6 h-6 text-center rounded-full">DF</span> }
            { glutenFree && <span className="border border-black py-1 mr-2 text-black text-xs w-6 h-6 text-center rounded-full">GF</span> }
            { sugarFree && <span className="border border-black py-1 text-black text-xs w-6 h-6 text-center rounded-full">SF</span> }
        </div>
    )
}